const _ = require('lodash');


const isValidPath = val => {
  if(_.some(_.split(val, '.'), test => test === '')) {
    return false;
  }
  return true;
}

const dataTypes = [
  'Value_Text',
  'Value_Boolean',
  'Value_Int',
  'Value_Date',
  'Value_List',
  'Value_Attachment',
  'Value_LText',
];

const normalizeValues = (values) => {
  return values.map(val => {
    if(_.isString(val)) {
      return _.trim(val);
    }
    return val;
  })
};

exports.toMetaDataObject = (items) => {
  const rtn = {};
  _.map(items, ({ data }) => {
    const { Name, Key } = data;
    const values = normalizeValues(_.values(_.pick(data, dataTypes)));
    if(!_.isNull(Name) && !_.isNull(Key)) {
      const dataPath = `${Name}.${Key}`;
      if(dataPath && isValidPath(dataPath)) {
        const value = _.find(values, test => !_.isNull(test));
        _.set(rtn, `${Name}.${Key}`, value);
      }
    }
  });
  return rtn;
};
