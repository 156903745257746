import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import { toMetaDataObject } from '../toMetaDataObject';

const useMeta = () => {
  const metaData = useStaticQuery(graphql`
    query MetaQuery {
      data: allAirtable(filter: {table: {eq: "Meta"}}) {
        items: nodes {
          id
          data {
            Name
            Key
            Value_Text
            Value_Boolean
            Value_Int
            Value_Date
            Value_List
            Value_Attachment {
              id
              size
              type 
              publicURL: url
              filename
            }
            Value_LText
          }
        }
      }
    }
  `);
  return toMetaDataObject(metaData.data.items);
};

export const useMetaOverride = (name = 'meta', item = {}) => {
  const metaData = useMeta();
  return _.defaultsDeep({ frontmatter: _.get(metaData, name, {}) }, item);
};

export default useMeta;

